/* @import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities'; 
 */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.landunder {
  text-decoration: underline;
}
.wavy {
  text-decoration: wavy;
  color:rgb(0, 0, 0);
}
.bgw-cb {
  background-color: whitesmoke;
  color: black;
  min-width: 15px;

}
/* .mainHeader {
  margin-right: 50%;
  text-align: left;
  justify-content: left;
  flex: content;
  display: flex;

} */
.swm {
  background-color: white;
  color: green;
  cursor: pointer;
  margin: 5%;
  padding: 3% 100px;
  text-align: center;
  border-top: 5px solid red;
  border-right: 5px solid blue;
  border-left: 5px solid red;
  border-bottom: 5px solid blue;
  border-radius: 10%;
  font-weight: 800;
  text-decoration: wavy;
}

.disabledHeader {
  color: #999; /* Dim text color */
  border-color: #999; /* Dim border color */
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMDMwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xNSAwTDMwIDE1TDE1IDMwTDAgMTVMMTUgMFoiIGZpbGw9InJlZCIvPjxwYXRoIGQ9Ik0xNSAzMEwzMCAxNUwxNSAwTDAgMTVMMTUgMzBaIiBmaWxsPSJyZWQiLz48L3N2Zz4=');
  background-repeat: no-repeat;
  background-position: center; /* Center the background image */
  background-size: 50%; /* Adjust size as needed */
}
.aci{
  background-image: url('./GREENP4P.webp');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.home{
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /* background-image: url('https://t3.ftcdn.net/jpg/00/92/80/64/360_F_92806458_cuy3wFTL9h8FqisKPbd9yGoihsNjy8xQ.jpg'); */
   /* background-image: url('https://image.shutterstock.com/image-vector/empty-cage-martial-arts-fighting-260nw-1136825033.jpg'); */
   /* background-image: url('./GREENP4P.webp');
   background-repeat: no-repeat;
  background-size: 100% 100%; */
  padding-top: 0%;
  padding-bottom: 0%;
  /* z-index: 1; */

}
.homep4p{
   background-image: url('./GREENP4P.webp');
   background-repeat: no-repeat;
  background-size: 100% 100%;
 

}
.homeSpace{
  padding-top: 10%;
  background-color: rgba(245, 245, 245, 0.26);
  color:black
}
.rentingSpace{
  padding-top: 10%;
  background-color: whitesmoke;
  color:black
}
.bg-black-trans{
  background-color: rgba(0, 0, 0, 0.452);
}
.text-alight-right{
  text-align: right;
  padding-right: 2%;
}

.crdiv {
  background-color: white ;
  color: blue;
  /* border: 7px solid black; */
  cursor: pointer;
  margin: 5%;
  padding: 3% ;
  text-align: center;
  border: black 5px solid;
  border-right: blue 5px solid;
  border-left: red 5px solid;
  color: white;
  font-weight: 800;
  width: 40%;
  z-index: 1;


}

.crdiv2 {
  background-color: rgb(0, 0, 0) ;
  color: blue;
  /* border: 7px solid black; */
  /* cursor: pointer; */
  margin: 5% 5% 0% 5%;
  padding: 3% 3% 0% 3% ;
  text-align: center;
  border: white 5px solid;
  /* border-radius:5%; */
  font-weight: 800;
  width: 40%;
  min-width: fit-content;
  /* position: relative; */


}
.text-align-center {
  text-align: center;
}
.background-purple {
  background-color: rgb(58, 0, 58);
}
.snow {
  text-decoration: wavy;

}
.homeheaders {
  text-align: center;
  margin-top: 0%;
  color: white;
  text-shadow: 0 0 5px rgba(55, 0, 59, 0.5); /* Corrected syntax */
  font-size: 25px;
  max-width: 90%;
  font-family:'Copperplate', 'Arial Narrow', Arial, sans-serif;
}
.homeheaders2 {
  text-align: right;
  margin: 0%;
  color: white;
  text-shadow: 0 0 5px rgba(55, 0, 59, 0.5); /* Corrected syntax */
  font-size: 15px;
  /* max-width: 90%; */
  font-family:'Copperplate', 'Arial Narrow', Arial, sans-serif;
}
.iframe-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.purple2 {
  color: rgb(32, 3, 86);
}
.section{
  height: 225px;
}

.side-borders-p4p{
  border-right: blue 5px solid;
  border-left: red 5px solid;
}

.about {
  color:rgb(255, 255, 255);
  text-align: center;
  justify-content: center;
  background-image: url('https://www.tilingtextures.com/wp-content/uploads/2017/04/0054-2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 0%;
  height: 280px;
  /* border: black 5px solid; */
  border-right: blue 5px solid;
  border-left: red 5px solid;

}
.aboutPetr{
  margin-right: 10%;
  margin-left: 10%;
}
.about h1{
  color:gold;
  text-align: center;
}
.about h4{
  color:rgb(255, 255, 255);
  text-align: center;
  line-height: 2.5em;
  margin-right: 20%;
  margin-left: 20%;
}


/* .about img {
  background-color: gold;
  height: 100px;
  padding: 10%;
  border: solid purple 10px;
  border-radius: 30%;

  
}  */
.resultsimg {
  background-color: whitesmoke;
  height: 50px;
  padding: 5%;
  border-top: 10px solid red;
  border-right: 5px solid blue;
  border-left: 5px solid red;
  border-bottom: 10px solid blue;
  border-radius: 30%;
  justify-content: center;
  text-align: center;
  margin-left: 40%;
  margin-right: 40%;
  width: 10%

  
}

.backB {
  background-color: gold;
  color:rgb(58, 0, 58) ;
  width:min-content;
  padding: 1%;
  border: solid purple 10px;
  border-radius: 30%;
  font-size: x-large;
  font-weight: bolder;
  margin-bottom: 2%;
  cursor: pointer;
  

}
.width-90 {
  max-width: 90%;
  min-width: 90%;
  text-align: left;
}
.donateB {
  background-color: whitesmoke;
  margin-right: 10%;
  margin-left: 10%;
  color:black;
  width: 15%;
  min-width: min-content;
  padding: 2%;
  border-top: solid red 10px;
  border-bottom: solid blue 10px;
  border-right: solid blue 10px;
  border-left: solid red 10px;
  border-radius: 30%;
  font-size: x-large;
  font-weight: bolder;
  margin-bottom: 2%;
  margin-top: 5%;
  cursor: pointer;
}
.redbutton {
  border: red solid 10px;
  color: red;
  background-color: whitesmoke;
  padding: 1% 3%;
  margin: 2%;
  font-size: 15px;
  font-weight: 800;
  cursor: pointer;
}
.bluebutton {
  border: blue solid 10px;
  color: navy;
  background-color: whitesmoke;
  padding: 1% 3%;
  margin: 2%;
  font-size: 15px;
  font-weight: 800;
  cursor: pointer;
}

.blackBG {
  background-color: black;
}
.whiteBG {
  background-color: white;
}


.filterbutton {
  margin-bottom: 2.5%;
}
.method {
  color:gold;

}
.methodbar {
  background-color: whitesmoke;
  border-top: red 4px solid;
  border-right: blue 3px solid;
  border-left: red 3px solid;
  border-bottom: blue 4px solid;
  padding: 1%;
  color: black;
  font-weight: bold;
  margin-top: 1%;
  border-radius: 10%;
}
.methodbar.selected {
  background-color: black;
  border: green 3px solid;
  padding: 1%;
  color: white;
  font-weight: bold;
  margin-top: 1%;
  border-radius: 15%;
}
.methodbar2 {
  background-color: whitesmoke;
  border-top: red 4px solid;
  border-right: blue 3px solid;
  border-left: red 3px solid;
  border-bottom: blue 4px solid;
  padding: 1%;
  color: black;
  font-weight: bold;
  margin-top: 1%;
  border-radius: 10%;
}
.methodbar2.selected {
  background-color: black;
  border: green 3px solid;
  padding: 1%;
  color: white;
  font-weight: bold;
  margin-top: 1%;
  border-radius: 15%;
}

.dreamcardbutton {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 6px;
  padding-left: 10%;
  overflow: hidden;
  font-weight: bold;
  background: darkblue;
  border-radius: 5px;
  color: white;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  font-size: 16px;
  margin-top: 1%;

  &::before {
    content: "";
    position: absolute;
    width: 48px;
    height: 48px;
    background: red; /* purple color */
    border-radius: 50%;
    transform: rotate(-40deg);
    bottom: 0;
    left: 0;
    transition: all 0.5s ease-out;
    transform: translate(-100%, 100%);
    margin-bottom: 36px;
    margin-left: 36px;
  }

  &:hover {
    &::before {
      margin-left: 0;
      margin-bottom: 96px;
      transform: translate(0, 0);
    }
    background: darkred;
    color: white;
    cursor:pointer;
  }
}


.method.selected {
  /* Change background color and border color for selected method */
  
  color: black;
  
}


.width-30 {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
}


.snowwhite{ 
  color: white;
  text-decoration: wavy;

}
.color-white{
  color:white;
  background-color: black;
  
}
.dnd {
  text-align: center;
  
  
  
}
.pupCirc {
  border: blue solid 3px;
  background-color: darkred;
  
  
  
  
}
.wid100 {
  min-width: 100%;
}
.bg-navy{
  /* background-color: navy;  */
  /* border-right: 6px white solid; */
  width: 100%;

  }
.bg-greey{
  background-color: rgb(211, 211, 211);
  border-right: 5px solid blue;
  border-left: 5px solid red;
  padding-bottom: 3%;
  }
.bg-darkred{
  /* background-color: darkred; */
  width: 100%;
  /* border-left: 6px white solid; */

}
.white-border2 {
  border: 6px white solid;
  border-right: 6px white solid;

}
.pred {
  color: red;
  background-color: black;
  padding: 1%;
  border: darkred 1px solid;
}
.pdraw {
  color:black;
  background-color: snow;
  padding: 0.5%;
  border-top: red 3px solid;
  border-left: red 3px solid;
  border-right: blue 3px solid;
  border-bottom: blue 3px solid;
  border-radius: 30%;


}

.pblue {
  color:navy;
  background-color: snow;
  padding: 1%;
  border: black 1px solid;
}
.caged-black {
  background-color: black;    
  color: white;
}
.ltypediv {
  background-color: lightgrey;
  border: black 3px solid;
  padding: 2%;
  margin-bottom: 1%;
  border-radius: 10%;
}
.swm2 {
  background-color: white;
  /* color: green; */
  border: 2px solid black;
  cursor: pointer;
  margin: 5%;
  
  padding: 3% 100px;
  text-align: center;
  border: #282c34 5px solid;
  /* border-radius:5%; */
  font-weight: 700;
}
/* CSS for the button */
.show-button {
  background-color: green; /* Green background when showPredictions is true */
  color: white;
   /* White text color for better contrast */
}

.hide-button {
  background-color: red; /* Red background when showPredictions is false */
  color: white; /* White text color for better contrast */
}
.allshow-button {
  background-color: green; /* Green background when showPredictions is true */
  color: white;
  padding: 1%;
  border-radius: 25%; /* White text color for better contrast */
}

.allhide-button {
  background-color: red; /* Red background when showPredictions is false */
  color: white; /* White text color for better contrast */
}
.bay {
  background-color: black;
  color: white;
  /* text-shadow: 0px 0px 5px white; */
  border-bottom: 10px white solid;
  text-decoration: wavy;
  margin-bottom: 0%;
}
.ggg {
  border-radius: 50%;
  border-top: red solid 5px;
  border-bottom: blue solid 5px;
  border-right: blue solid 5px;
  border-left: red solid 5px;
}
.bg-darkred-navy{
  background: linear-gradient(to right, red 50%, blue 50%);
  
}
.br-50{
  border-radius: 50%;
}

.sum41header {
  position: relative; /* Needed for z-index to work */
  z-index: 10; /* Higher than the background div */
}

.background-div {

}








@keyframes shake {
  0% { transform: translateX(0); }
  1% { transform: translateY(0); }
  25% { transform: translateX(-2px); }
  35% { transform: translateY(-2px); }
  50% { transform: translateX(2px); }
  60% { transform: translateY(2px); }
  75% { transform: translateX(-2px); }
  85% { transform: translateY(-2px); }
  99% { transform: translateX(0); }
  100% { transform: translateY(0); }
}

.shake {
  animation: shake 0.7s; /* Run the shake animation */
  animation-iteration-count: infinite; /* Loop the animation forever */
}
@keyframes fake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-10px); }
  20%, 40%, 60%, 80% { transform: translateX(10px); }
}

.fake:hover {
  animation: shake 0.7s ease-in-out;
  animation-iteration-count: infinite;
  color: white;
  background: linear-gradient(to right, darkred 50%, navy 50%);
  cursor: pointer;
}
@keyframes bake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-10px); }
  20%, 40%, 60%, 80% { transform: translateX(10px); }
}

.bake:hover {
  animation: shake 0.6s ease-in-out;
  animation-iteration-count: infinite;
  
  cursor: pointer;
}
.bg-aboutbb{
  background-image: url('./bg-baby.webp');
  background-repeat: no-repeat;
  /* background-position-x: center; */
	background-size: 100% 150%;
}
.bg-cat{
  background-image: url('./tqtcat.jpeg');
  background-repeat: no-repeat;
  /* background-position-x: center; */
	background-size: 100% 100%;
}

.rvb {
  width: 100%;
  /* height: 10px; */
  background: linear-gradient(to right, red 50%, blue 50%);
}
.rvb2 {
  width: 100%;
  /* height: 10px; */
  background: linear-gradient(to right, darkred 50%, navy 50%);
}
.aboutTony{
  margin-right: 20%;
  margin-left: 20%;

}
.white75{
  background-color: rgba(245, 245, 245, 0.724);
}
.element-with-border {
  width: 100%;
  height: 10px;
  border-top: 3px solid;
  background: linear-gradient(to right, red 50%, blue 50%);
}
.element-with-borderBB {
  width: 100%;
  height: 10px;
  /* border-bottom: 3px solid; */
  background: linear-gradient(to right, red 50%, blue 50%);
}
.element-with-borderTB {
  width: 100%;
  height: 10px;
  /* border-top: 3px solid; */
  background: linear-gradient(to right, red 50%, blue 50%);
}
.element-with-border2 {
  background: linear-gradient(to right, darkred 50%, navy 50%);
  width: 100%;
  height: 10px;
  border-top: 3px solid;
}
.color-blue-light{
  color: #4877db;
}
.element-with-border22 {
  background: linear-gradient(to right, darkred 50%, navy 50%);
  width: 100%;
  height: 10px;
  
}
.element-with-border3 {
  width: 100%;
  height: 150%;
  
  background: linear-gradient(to right, darkred 50%, navy 50%);
  
}
.landunder {
  text-decoration: underline;
}
.blueDelete {
  margin: 5%;
  padding : 3% 5%;
  background-color: whitesmoke;
  color :navy;
  border: 2px solid navy;
  cursor: pointer;
}
.Xmas {
  font-weight: 700;
  background-color: red;
  color: white;
  border-radius: 50%;
  border: red 3px solid;
}
.greencircle {
  background-color: green;
  color: white;
  font-weight: 400;
  padding: 2%;
  border-radius: 50%;

}
.color-greenBB{
  background-color: green;
  color: white;
  font-weight: 400;
}
.redDelete {
  margin: 5%;
  padding : 3% 5%;
  background-color: darkred;
  color :white;
  cursor: pointer;
  /* border: 2px solid red */
}
.height-100per {
  max-height: 100%;
  min-height: 100%;
}
.bayLoc{
  background-color: black;
  color: whitesmoke;
  /* text-shadow: 0px 0px 5px white; */
  
  text-decoration: wavy;
  /* margin-bottom: -3%; */
}
.aboutL{
  margin: 0 10%;
  padding-bottom: 35%;

}
/* CSS for hiding and showing predictions */
.hidden-predictions {
  display: none;
}
.color-black{
  color: black;
}
.color-blue {
  color:blue;
  text-decoration: wavy;
}
.color-red {
  color:red;
  text-decoration: wavy;
}
.color-green {
  color:green;
  text-decoration: wavy;
}
.color-yellow {
  color:yellow;
  text-decoration: wavy;
}
.color-purple {
  color:purple;
  text-decoration: wavy;
}
.color-orange {
  color: orange;
  text-decoration: wavy;
}
.color-gold {
  color: gold;
  text-decoration: wavy;
}
.color-transp {
  color: #ffffff00;
  color: rgb(61, 61, 61);
}

.select{
  border: #2f2f2f 3px solid;
  border-radius: 5%;
}
.payment {
  text-align: center;
}
.p4pplusBlack {
  max-width: 50px;
  min-width: 50px;
  max-height: 50px;
  min-height: 50px;
  height:auto;
  background-image: url('./p4p+black.png');
  background-repeat: no-repeat;
  /* background-position-x: center; */
	background-size: 100% 100%;
  /* border: red solid 2px; */
  padding: 5% 15%;

}
.p4pplusBlack2 {
  max-width: 100px;
  min-width: 100px;
  max-height: 100px;
  min-height: 100px;
  height:auto;
  background-image: url('./p4p+black.png');
  background-repeat: no-repeat;
  /* background-position-x: center; */
	background-size: 100% 100%;
  /* border: red solid 2px; */
  padding: 5% 15%;

}
.p4pplusBlack23 {
  max-width: 70px;
  min-width: 70px;
  max-height: 70px;
  min-height: 70px;
  height:auto;
  background-image: url('./p4p+black.png');
  background-repeat: no-repeat;
  /* background-position-x: center; */
	background-size: 100% 100%;
  /* border: red solid 2px; */
  padding: 5% 15%;
  margin-top: -5%;

}
.p4pHI {
  max-width: 50px;
  min-width: 50px;
  max-height: 50px;
  min-height: 50px; 
  height:auto; 
  background-image: url('./p4p+whitegreen.png');
  background-repeat: no-repeat;
  /* background-position-x: center; */
	background-size: 100% 100%;
  

}
.bg-darknavy{
  background-color: rgba(1, 1, 83, 0.454);
}
.p4pplusWhite {
  max-width: 50px;
  min-width: 50px;
  max-height: 50px;
  min-height: 50px;
  height:auto;
  background-image: url('./p4p+whitegreen.png');
  background-repeat: no-repeat;
  /* background-position-x: center; */
	background-size: 100% 100%;
  /* border: red solid 2px; */
  padding: 5% 15%;

}
.p4pplus {
  max-width: 100%;
  min-width: 80px;
  max-height: 40px;
  min-height: 40px;
  height:auto;
  background-image: url('./p4p+whitegreen.png');
  background-repeat: no-repeat;
  /* background-position-x: 50%; */
	background-size: 100% 100%;
  /* border: red solid 2px; */
  padding: 5% 25%;

}
.apple {
  max-width: 33%;
  min-width: 33%;
  max-height: 100%;
  min-height: 150px;
  height:auto;
  /* background-position: 20%; */
  background-image: url('./logo.png');
  background-repeat: no-repeat;
  background-position-x: 50%;
	background-size: 100% 100%;
  /* background-color: white; */
  /* border-bottom: blue 5px solid;
  border-top: red 5px solid;
  border-right: blue 5px solid;
  border-left: red 5px solid; */
  margin-left: 33%;
  text-align: center;
  /* padding:5%; */
  /* border-radius: 25%; */
  /* margin: 0; */
  animation: App-logo-spin infinite 2s linear;

}
.juice {
  max-width: 33%;
  min-width: 33%;
  max-height: 100%;
  min-height: 150px;
  height:auto;
  background-image: url('./whitelogo.png');
  background-repeat: no-repeat;
  background-position-x: 50%;
	background-size: 100% 100%;
  margin-left: 33%;
  text-align: center;
  animation: App-logo-spin infinite 2s linear;
}

.f-Man {
  max-width: 33%;
  min-width: 33%;
  max-height: 100%;
  min-height: 150px;
  height:auto;
  background-image: url('./fightingMan.png');
  background-repeat: no-repeat;
  background-position-x: 50%;
	background-size: 100% 100%;
  margin-left: 33%;
  text-align: center;
  animation: App-logo-spin infinite 2s linear;
}
.f-Man1 {
  max-width: 33%;
  min-width: 33%;
  max-height: 100%;
  min-height: 150px;
  height:auto;
  background-image: url('./fightingMan1.png');
  background-repeat: no-repeat;
  background-position-x: 50%;
	background-size: 100% 100%;
  margin-left: 33%;
  text-align: center;
  animation: App-logo-spin infinite 2s linear;
}
.f-Man2 {
  max-width: 33%;
  min-width: 33%;
  max-height: 100%;
  min-height: 150px;
  height:auto;
  background-image: url('./fightingMan2.png');
  background-repeat: no-repeat;
  background-position-x: 50%;
	background-size: 100% 100%;
  margin-left: 33%;
  text-align: center;
  animation: App-logo-spin infinite 2s linear;
}
.f-Man3 {
  max-width: 33%;
  min-width: 33%;
  max-height: 100%;
  min-height: 150px;
  height:auto;
  background-image: url('./fightingMan3.png');
  background-repeat: no-repeat;
  background-position-x: 50%;
	background-size: 75% 75%;
  margin-left: 33%;
  text-align: center;
  animation: App-logo-spin infinite 2s linear;
}
.sirealpfl{
    margin-top: 300px;
    min-width: 100%;

}
.f-Man4 {
  max-width: 33%;
  min-width: 33%;
  max-height: 100%;
  min-height: 150px;
  height:auto;
  background-image: url('./fightingMan4.png');
  background-repeat: no-repeat;
  background-position-x: 50% 50%;
	background-size: 75% 75%;
  margin-left: 33%;
  text-align: center;
  animation: App-logo-spin infinite 2s linear;
}

/* //////////INVERT THIS//////// */
.f-Man4R {
  max-width: 33%;
  min-width: 33%;
  max-height: 100%;
  min-height: 150px;
  height: auto;
  background-image: url('./fightingMan4R.png');
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-size: 100% 100%;
  margin-left: 33%;
  text-align: center;
  animation: App-logo-spin infinite 2s linear;
  transform: scaleX(-1); /* Invert horizontally */
}
.f-Man5 {
  max-width: 33%;
  min-width: 33%;
  max-height: 100%;
  min-height: 150px;
  height:auto;
  background-image: url('./fightingMan5.png');
  background-repeat: no-repeat;
  background-position-x: 50%;
	background-size: 100% 100%;
  margin-left: 33%;
  text-align: center;
  animation: App-logo-spin infinite 2s linear;
}
.f-Man6 {
  max-width: 33%;
  min-width: 33%;
  max-height: 100%;
  min-height: 150px;
  height:auto;
  background-image: url('./fightingMan6.png');
  background-repeat: no-repeat;
  background-position-x: 50%;
	background-size: 100% 100%;
  margin-left: 33%;
  text-align: center;
  animation: App-logo-spin infinite 2s linear;
}
.f-Man7 {
  max-width: 33%;
  min-width: 33%;
  max-height: 100%;
  min-height: 150px;
  height:auto;
  background-image: url('./fightingMan7.png');
  background-repeat: no-repeat;
  background-position-x: 50%;
	background-size: 100% 100%;
  margin-left: 33%;
  text-align: center;
  animation: App-logo-spin infinite 2s linear;
  
}
.p4p8 {
  max-width: 33%;
  min-width: 33%;
  max-height: 100%;
  min-height: 150px;
  height:auto;
  background-image: url('./p4p+black.png');
  background-repeat: no-repeat;
  background-position-x: 50%;
	background-size: 100% 100%;
  margin-left: 33%;
  text-align: center;
  animation: App-logo-spin infinite 2s linear;

}
  .leagueBG {
    background-image: url('./leagueObg.jpeg');
    background-repeat: no-repeat;
    /* background-position-x: 50%; */
    background-size: 100% 100%

  }
  .leagueBGH {
    background-image: url('./leagueHeader.jpeg');
    background-repeat: no-repeat;
    /* background-position-x: 50%; */
    background-size: 100% 100%;
    margin-left: -3.5%;
    /* padding-left: -1%; */

  }
  .p4pBelt {
    background-image: url('./p4pBelt.jpeg');
    background-repeat: no-repeat;
    /* background-position-x: 50%; */
    background-size: 100% 100%;
    min-height: 150px;
    margin: 0 5%;
    /* margin-left: -3.5%; */
    /* padding-left: -1%; */

  }
  .p4pBelt2 {
    background-image: url('./p4pBelt.jpeg');
    background-repeat: no-repeat;
   
    background-size: 100% 100%;
   
  }
  .rvb22 {
    background-image: url('./rvb22.webp');
    background-repeat: no-repeat;
    background-size: cover; /* Or 100% auto; depending on the desired effect */
    background-position-x: 30%; /* Shifts the image down to hide the top 30% */
}

  


  .cursor-pointer{
    cursor: pointer;
  }
.border-1px-black{
  border: black 1px solid;
}
.br15{
  border-radius: 15%;

} 
.width
.p2{
  padding: 2%;
}
.pt95{
  text-align: start;
  padding-left: 1%;
}
.margin5{
  margin: 5%;
}
.paddingupdown{
  padding-top :10%;
  padding-bottom :10%
}
.insideBelt{
  /* font-family: monospace; */
  font-weight: 600;
  font-size: 20px;
}
.green-bg{
  background-color: darkgreen;
  color:white;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); /* Start from 360 degrees (full rotation) */
  }
  to {
    transform: rotate(360deg); /* End at 0 degrees (original position) */
  }
}


.small {
  font-size: smaller;
}
.tommy {
  text-align: center;
  /* background-color: black; */
  color:black;
  margin-bottom: 0%;
  padding-bottom: 15%;
  max-width: 100%;
  /* background-image: url('https://t3.ftcdn.net/jpg/00/92/80/64/360_F_92806458_cuy3wFTL9h8FqisKPbd9yGoihsNjy8xQ.jpg');
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
} 

.fighter-button {
  background-color: black;
  color: black;
  border-top: 5px  solid black;
  border-bottom: 5px  solid black;
  height: 100px;
  margin-right: 10px;
  border-radius: 10%;
  font-weight: 700;
  cursor: pointer;
  min-width: 80%;
  background-size: 100% 100%;
  
}
.pfl-button {
  background-color: black;
  color: black;
  border-top: 5px  solid black;
  border-bottom: 5px  solid black;
  height: 100px;
  margin-right: 10px;
  border-radius: 10%;
  font-weight: 700;
  /* cursor: pointer; */
  width: 60%;
  margin: 0 20%;
  background-size: 100% 100%;
  
}
.h2h {
  padding: 5% 2%;
  border-radius: 55%;
  font-weight: 900;
  font-size: larger;
  
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
font-weight: bold;
}
.wsignal {
  padding: 5% 10%;
  
  color: white;
  
}
.h2header {
  background-color: snow;
  color: black;

}
.submitb{
  background-color:white ;
  color: black ;
  /* border: black 5px solid; */
  border-right: blue 5px solid;
  border-left: red 5px solid;
  border-top: red 5px solid;
  border-bottom: blue 5px solid;
  padding: 4% 3% ;
  margin-top: 5%;
  border-radius: 10%;
  font-size: larger;
  font-weight: 700;
  cursor: pointer;
  min-width: 45%;
}

.submitb2{
  background-color:white ;
  color: black ;
  /* border: black 5px solid; */
  border-right: blue 5px solid;
  border-left: red 5px solid;
  border-top: red 5px solid;
  border-bottom: blue 5px solid;
  /* padding: 2% 3% ; */
  margin-top: 5%;
  border-radius: 10%;
  /* font-size: larger; */
  /* font-weight: 700; */
  cursor: pointer;
  min-width: fit-content;
}
.bold {
  font-weight: bold;
}
.marginB0 {
  margin-bottom: 0%;
}
.errortime{
  background-color: white;
  border: red 3px solid;
  margin-left: 25%;
  margin-right: 25%;
  font-weight: bolder;
  color:darkred
}
.pointEX {
  border: black 5px solid;
  border-right: blue 5px solid;
  border-left: red 5px solid;
  
  
  background-color: rgba(0, 0, 0, 0.439);
  
}
.pointEXCard {
  border: darkred 5px solid;
  margin-right: 20%;
  margin-left: 20%;
  text-align: center;
  margin-top: 0%;
  
  
  background-color: rgba(4, 11, 85, 0.565);
  
}
.pointEXB {
  border: gold 10px dotted;
  margin-right: 20%;
  margin-left: 20%;
  background-color: rgba(0, 0, 0, 0.439);
  margin-bottom: 2%;

}
.tight {
  margin: 0%;
  padding: 0%;
  
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.pointEXB2 {
  border: blue 10px dotted;
  margin-right: 20%;
  margin-left: 20%;
  margin-bottom: 2%;
  text-align: center;

}
.expoint {
  border: darkred 3px solid;
  background-color: white; 
  color: navy;
  cursor: pointer;
  margin-bottom: 5%;
  font-weight: bold;
}
.urpicksB {
  border: darkred 3px solid;
  background-color: snow; 
  color: navy;
  cursor: pointer;
  margin-bottom: 5%;
  font-weight: bold;

}
.b2fight {
  border: black 3px solid;
  background-color: gold; 
  color: rgb(12, 10, 80);
  cursor: pointer;
  margin-bottom: 5%;
  font-weight: bold;

}
.tac {
  text-align: center;
  color: gold;
  font-weight: bold;
 
}
.Left5 {
  
  padding-left: 5%;
 
}
.Left6 {
  
  padding-left: 6%;
 
}

.lboard {
  text-align: center;
  background: linear-gradient(to right, darkred 50%, navy 50%);
  color:white;
  border: 5px white solid;
  margin-bottom: 5%;
  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 2%;
}
.downUnder {
  text-decoration: underline;
}
.Header{
  background-color:black ;
}
.padding1{
  padding: 1%;
}
.filterbutton {
  border : 3px solid darkred;
  font-weight: bold;
  width: 50%;
}
.delete-button {
  background-color: gold;
  border : 3px solid red;
  margin-top: 0%;
  cursor: pointer;
  font-weight: bold;
}
.results {
  /* background-image: url('https://c4.wallpaperflare.com/wallpaper/121/402/526/anderson-silva-boxing-fight-fitness-wallpaper-preview.jpg'); */
  background-image: url('https://dmxg5wxfqgb4u.cloudfront.net/styles/background_image_sm/s3/2020-04/Crowd-Fan-Generic-198.jpg?itok=VMsavZNn');
  background-repeat: no-repeat;
  background-size: 100% 120%;
  color:white;
  

}
.LeftOne2 {
  background-color: whitesmoke;
  width: 24%;
}

.RightOne2 {
  /* background-color: navy; */
  width: 74%;
}
.LeftOne {
  /* background-color: pink; */
  width: 50%;
}


.RightOne {
  /* background-color: navy; */
  width: 50%;
}
.wholeOne{
  min-width: 100%;
  background-color: #282c34;
}
.wholeOne2{
  min-width: 100%;
  background: linear-gradient(to right, darkred 50%, navy 50%);

}
.notLchosenL{
  margin: 5%;
  border-right: blue 5px solid;
  border-left: red 5px solid;
  border-top: red 5px solid;
  border-bottom: blue 5px solid;
  cursor: pointer;


}
.flex-start {
  display: flex;
  flex-direction: row; /* or column, depending on your desired direction */
  flex-wrap: nowrap; /* or wrap, depending on your desired wrapping behavior */
  justify-content: flex-start; /* align items along the start of the flex container */
  align-items: flex-start; /* align items along the start of the cross axis */
}
.mmaO{
  border-radius: 25%;
}
.mmaOW{
  
  border-right: white 5px solid;
  border-left: white 5px solid;
  border-bottom: white 5px solid;

}

.chosenL{
  margin: 15%;
  border: 3px solid black;
  border-radius: 15%;
  background-color: white;
  color: black;
  cursor: pointer;
  

}
.leaguebutton:hover {
  color: white;
  background-color: black; /* Change this to charcoal or any color you prefer */
}
.skinnyBoy{
  max-width: fit-content;
}
.margin-5{
  margin: 5% ;
}
.margin-0-5{
  margin: 0 5% ;
}
.border-radius-20per{
  border-radius :20%;
}

.padding-5-0{
  padding: 5% 0px;
}
.width-31{
  width: 31%;
}
.padding-10-0{
  padding: 1% 0%;
}
.margin1{
  margin: 1%;
}
.joinL{
  background-color: black;
  width: 90%;
  margin: 5%;
  border-right: blue 2px solid;
  border-left: red 2px solid;
  border-top: red 2px solid;
  border-bottom: blue 2px solid;
  margin-bottom: 2%;
}
.pickresultsdiv {
  background-color:whitesmoke ;
  color: black;
  border-left: black 3px solid;
}
.pickresultsdiv h2 {
  background-color: darkblue;
  margin-top: 2%;
  padding-bottom: 2%;
  color: white;
}
.ownpicksdiv {
  background-color: whitesmoke;
  color: black;
  border-right: black 3px solid ;
}
.ownpicksdiv h2 {
  background-color: darkred;
  margin-top: 2%;
  padding-bottom: 2%;
  color: white;
}
.equador{
  padding-bottom: 30px;
  
}
.fgpsi{
  border: #0c0c0c 2px solid;
  padding: 1% 3%;
  min-width: 90%;
  border-radius: 15%;
}
.loadingL {
  background-image: url('./zzZZ.gif');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  min-height: '30px';
  /* margin-right: 35%;
  margin-left: 30%; */
  padding-top: 20%;
}
.loading {
  background-image: url('./zzZZ.gif');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: '30px';
  /* margin-left: '3%'; */
  text-align: right;
}
.loading2 {
  background-image: url('./zzZZ.gif');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  min-height: '30px';
  margin-right: 35%;
  margin-left: 30%;
  padding-top: 2%;
}
.loading3 {
  background-image: url('./zzZZ.gif');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: '30px';
  /* margin: 25%; */
  /* max-width: 50%; */
  margin-top: 5%;

}
.loginsu{
  text-align:right;
  color: white;
   margin-right:35%;

}
.pt20{
  padding-top: 20%;
}
.selectO {
  
  color: black;
  border: 1px  solid black;
  

}

.fighter-button.selected {
  background-color: green;
  color: darkgreen;
  border: 5px  solid green;

}
.results-container {
  border: black 2px solid;
  max-height: 150px;
  min-height: 150px;
  margin: 5% 0%;

}
.real-results-container {
  border: grey 2px solid;
  max-height: 150px;
  min-height: 150px;
  margin: 5% 0%;

}
.winnerCircle {
  border: green solid 2px;
  max-height: 150px;
  min-height: 150px;
  margin: 5% 0%;
}
.rightgreen {
  background-color: green;
  color:white;
  /* margin-top: 500px; */
  border-radius: 50%;
  padding:2%;
  text-align: center;
  /* margin-left: 5%; */
}
.record-circles-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.recordwcir {
  background-color: black;
  color: white ;
  border-radius: 50%;
  font-weight: bold;
  padding: 1%;
  align-items: center;
  text-align: center;
  width: fit-content;
  margin: auto; /* Move circles to the left */
  margin-top: -10px; 
/* Add some spacing from buttons */
}
.bluecorner{
  border: blue 4px solid;
}
.redcorner{
  border: red 4px solid;
}
.fighter-button:not(.selected):hover {
  background-color: blue;
  color: white;
  border: 5px  solid blue;
}

.johnny {
  text-align: center;
  background-color: rgb(0, 0, 0);
  background-image: url("https://t3.ftcdn.net/jpg/03/57/92/94/360_F_357929406_33BPzrx3uSUOgwokmDZ4QpESUBrJJaqC.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: navy;
  margin-top: 0%;
  margin-bottom: 0%;
  padding-bottom: 5%;

}
.bold {
  font-weight: bold;
}
#basement{
  padding: 20px;
}

.errors {
  color: red;
  font-weight: bolder;
  font-size: large;
  background-color: white;
  /* border: 2px solid blue; */
  margin-left: 35%;
  text-align: center;
}
input[type='checkbox'] {
  width: 20px;
  height: 20px;
}
input[type='checkbox'] {
  border: 2px solid #333;
}
/* Unchecked */
input[type='checkbox']:not(:checked) {
  background-color: #fff;
}

/* Checked */
input[type='checkbox']:checked {
  background-color: #007bff;
}
/* Hide default checkbox */
input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* Use a custom icon */

/* Style the label */
input[type='checkbox'] + label {
  cursor: pointer;
}

/* Hide the default checkbox */
input[type='checkbox'] {
  opacity: 0;
  /* position: absolute; */
}

/* Show the custom icon when the checkbox is checked */
/* input[type='checkbox']:checked + label::before {
  background-color: #007bff;
  color: blue;
} */
.paddingneeds{
  padding: 5% 0%
}


.width25{
  width: 25%;
}






.custom-checkbox {
  width: 340px;
  height: 80px;
}
.custom-checkbox input#status {
  display: none;
}
.custom-checkbox input#status + label {
  height: 100%;
  width: 100%;
}
.custom-checkbox input#status + label > .status-switch {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: white;
  color: navy;
  font-weight: bold;
  transition: all 0.5s ease;
  padding: 3px;
  border-radius: 3px;
}
.custom-checkbox input#status + label > .status-switch:before, .custom-checkbox input#status + label > .status-switch:after {
  border-radius: 2px;
  height: calc(100% - 6px);
  width: calc(50% - 3px);
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  transition: all 0.3s ease;
}
.custom-checkbox input#status + label > .status-switch:before {
  background-color: darkred;
  color: white;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
  left: 3px;
  z-index: 10;
  content: attr(data-unchecked);
}
.custom-checkbox input#status + label > .status-switch:after {
  right: 0;
  content: attr(data-checked);
}
.custom-checkbox input#status:checked + label > .status-switch {
  background-color: white;
  color:darkred
}
.custom-checkbox input#status:checked + label > .status-switch:after {
  left: 0;
  content: attr(data-unchecked);
}
.custom-checkbox input#status:checked + label > .status-switch:before {
  color: white;
  background-color: navy;
  left: 50%;
  content: attr(data-checked);
}
#Svideo {
  background-color: rgb(190, 190, 190);
  border: 2px solid white;
  border-radius: 4px;
  display: inline-block;
  font: bold;
  font-size: medium;
  text-align: center;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  
  /* -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box; */
  /* box-sizing: border-box;
  -webkit-appearance: none; */
  /* -moz-appearance: none; */
}
#select {
  background-color: rgb(190, 190, 190);
  border: thin solid grey;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  /* -moz-appearance: none; */
}
#nono {
  background-color: rgb(255, 255, 255);
  /* border: none; */
  border-radius: 0px;
  display: auto;
  font: auto;
  line-height: auto;
  padding: auto;
  /* -webkit-box-sizing: border-box;
  -moz-box-sizing: none;
  box-sizing: none;
  -webkit-appearance: none;
  -moz-appearance: none; */
}
#select.arrows {
  background-image:    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAA3klEQVRIS+3VMU9CMRTF8d8zBL+aizoQFhx0kUk33RzdYMNFXUFnYeGrYYyaJiUxJHDLSxodbNKpfeffc9/pbaPyaCrr+3OA++z4rtT5Pg5GuMnCY9yWQEoBE1xhlUUP8YDrCBIB0vojLvGO0yz4hm4JJAKcYYoPHGOZAUdYoIMBXrc5iQAHeMlzviFygj7O8dkWEJU4XI8chALRhn9AVKHf70VRTHu4wFfbmKZLNKt50dLBnna0imcMd/2I0phWa3Y/D1e1Xa9BCZJG0VuQNpaWKMx72xS1Fl5/WN3BN+AgJhnZQlq4AAAAAElFTkSuQmCC');
  background-position: calc(100% - .5rem), 100% 0;
  background-size:  1.5em 1.5em;
  background-repeat: no-repeat;
  text-align: center;
  
}

#select.arrows:focus {
  border-color: blue;
  outline: 0;
}
.color-black {
  color: black
}
.white-border {
  border: solid white 1px;
}
.bg-white {
  background-color: white;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.mobile-fight-info {
  font-size: 1rem;
  color: white;
  white-space: nowrap;
  min-height: fit-content;
}
.mobile-fighter-name {
  font-size: 1.7rem;
  font-weight: bold;
  color: white;
}
.mobile-fighter-name.selected {
  font-size: 1.7rem;
  font-weight: bold;
  background-color: darkgreen;
  color: white;
  max-width: 50%;
}
.mobile-fighter-name.notselected {
  font-size: 1.7rem;
  font-weight: bold;
  background-color: black;
  color: white;
  max-width: 50%;
}
.redName{
  background-color: red;
  color: white;

}
.blueName {
  background-color: blue;
  color: white;
}
.fighterready{
  background-color: black;
  margin-top: 0px;
  margin-bottom: 0px;
  border-top: 3px white solid;
}
.fighterACTready{
  background-color: black;
  margin-top: 0px;
  margin-bottom: 0px;
  border-top: 6px darkgreen solid;
}
.compareheader{
  background-image: url('./compareheader2.png');
  background-repeat: no-repeat;
  background-position-y: 100%;
  background-size: 100% 100%;

}
.pfl-container {
  display: flex; /* Display children in a row (horizontal) */
  align-items: center; /* Vertically align children */
  margin-bottom: 20px; /* Add margin between fighter containers */
  min-width: 50%;
  /* min-height: 100%; */
  background-image: url('./redvblue.jpeg');
  background-repeat: no-repeat;
  background-position-y: 100%;
  background-size: 100% 100%;
  min-height: 500px;
}

.fighter-container {
  display: flex; /* Display children in a row (horizontal) */
  align-items: center; /* Vertically align children */
  margin-bottom: 20px; /* Add margin between fighter containers */
  min-width: 50%;
  /* min-height: 100%; */
  background-image: url('./GREENP4P.webp');
  background-repeat: no-repeat;
  background-position-y: 100%;
  background-size: 100% 100%;
}
.fc3{
  /* display: flex; Display children in a row (horizontal) */
  align-items: center; /* Vertically align children */
  /* margin-bottom: 20px; Add margin between fighter containers */
  min-width: 5%;
  padding: 0 25%;
  margin: 0 -25%;
  min-height: 00px;
  /* background-image: url('https://www.shutterstock.com/image-vector/empty-mma-arena-red-blue-260nw-1705409833.jpg'); */
  /* background-image: url('https://image.shutterstock.com/image-vector/empty-cage-martial-arts-fighting-260nw-1136825033.jpg'); */
  background-repeat: no-repeat;
  background-size: 100% 110%;
  /* position: relative; */
  

}
.inside-fighter-container {
  display: flex; /* Display children in a row (horizontal) */
  align-items: center; /* Vertically align children */
  margin-bottom: 20px; /* Add margin between fighter containers */
  min-width: 50%;
  /* min-height: 100%; */
  background-image: url('https://www.shutterstock.com/image-vector/empty-mma-arena-red-blue-260nw-1705409833.jpg');
  /* background-image: url('https://image.shutterstock.com/image-vector/empty-cage-martial-arts-fighting-260nw-1136825033.jpg'); */
  background-repeat: no-repeat;
  background-size: 100% 110%;
}
.fighter-container2 {
  display: flex; /* Display children in a row (horizontal) */
  align-items: center; /* Vertically align children */
  margin-bottom: 20px; /* Add margin between fighter containers */
  min-width: 50%;
  /* min-height: 100%; */
  background-image: url('https://cdn2.iconfinder.com/data/icons/greenline/512/check-512.png');
  /* background-image: url('https://image.shutterstock.com/image-vector/empty-cage-martial-arts-fighting-260nw-1136825033.jpg'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.home-fighter {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  
  min-width: 100%;
  margin-top: -390px;
  /* position: absolute;
  
  
   Set a negative z-index to position it behind other elements */
}
.home-fighter2 {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  
  min-width: 100%;
  margin-top: 0px;
  /* position: absolute;
  
  
   Set a negative z-index to position it behind other elements */
}
.z2{
  position: relative;
}
.fighter-image-tapout {
  flex: 1; /* Grow to fill available space */
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 60vh; */
  height:250px;
  border-radius: 25% ;
  
  /* overflow: hidden; */
}
.fighter-image {
  flex: 1; /* Grow to fill available space */
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 60vh; */
  min-height:600px;
  overflow: hidden;
}
.nice{
  margin-top: 0px;
  /* margin-bottom: 50px; */
}
.sireal {
  margin-top: 90%;
  min-width: 100%;

}
.fighter-info {
  flex: 1; /* Grow to fill available space */
  text-align: center;
  padding: 0px; /* Add padding to fighter info */
  width: 100%;
}

.selected {
  /* Add styles for the selected fighter */
  /* border: 5px solid green; Example border style for selected fighter */
  /* Add other selected fighter styles as needed */
}

.color-black {
  color: black
}


.justify-center{
  justify-content: center;
}





/* The switch - the box around the slider */
.switch {
  font-size: 17px;
  position: relative;
  display: flex; /* Use flexbox for centering */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  width: 8em;
  height: 3.5em;
  
  
}
.pborder {
  border-top: 5px solid red;
  border-right: 10px solid blue;
  border-bottom: 10px solid blue;
  border-left: 10px solid red;
}

/* Hide default HTML checkbox */
.switch .cb {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.toggle {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: #373737;
  border-radius: .1em;
  transition: .4s;
  text-transform: uppercase;
  font-weight: 700;
  overflow: hidden;
  box-shadow: -.3em 0 0 0 #373737,-.3em .3em 0 0 #373737,.3em 0 0 0 #373737,.3em .3em 0 0 #373737,0 .3em 0 0 #373737;
}

.toggle > .left {
  position: absolute;
  display: flex;
  width: 50%;
  height: 88%;
  background-color: #f3f3f3;
  color: darkred;
  left: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  transform-origin: right;
  transform: rotateX(10deg);
  transform-style: preserve-3d;
  transition: all 150ms;
}
.cursor-pointer{
  cursor: pointer;
}
.left::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgb(206, 206, 206);
  transform-origin: center left;
  transform: rotateY(90deg);
}

.left::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgb(112, 112, 112);
  transform-origin: center bottom;
  transform: rotateX(90deg);
}

.toggle > .right {
  position: absolute;
  display: flex;
  width: 50%;
  height: 88%;
  background-color: #f3f3f3;
  color: rgb(206, 206, 206);
  right: 1px;
  bottom: 0;
  align-items: center;
  justify-content: center;
  transform-origin: left;
  transform: rotateX(10deg) rotateY(-45deg);
  transform-style: preserve-3d;
  transition: all 150ms;
}

.right::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgb(206, 206, 206);
  transform-origin: center right;
  transform: rotateY(-90deg);
}

.right::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgb(112, 112, 112);
  transform-origin: center bottom;
  transform: rotateX(90deg);
}

.switch input:checked + .toggle > .left {
  transform: rotateX(10deg) rotateY(45deg);
  color: rgb(206, 206, 206);
}

.switch input:checked + .toggle > .right {
  transform: rotateX(10deg) rotateY(0deg);
  color: #4877db;
}




.border-buttop{
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.formL{
  padding-bottom: 3%;
}
.bg-white {
  background-color: white;
}
.fs45{
  font-size: 45px;
}
.fs20{
  font-size: 20px;
}
.fi {
  
  flex: 1; 
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
  /* min-width: 50%; */
  min-height:600px;
  overflow: hidden;
  padding-bottom: 3%;
}
.fi2 {
  
  flex: 1; 
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
  /* min-width: 50%; */
  min-height:300px;
  overflow: hidden;
  padding-bottom: 3%;
}
.homebullet{
  font-size: 20px;
}
.homebullet12{
  font-size: 12px;
}
.formProfilePatch{
  margin: 0% 5%;
  background-color: whitesmoke;
  padding: 5%;
}

.ProfilePicPreview{
  border-right: blue 5px solid;
  border-left: red 5px solid;
  border-top: red 5px solid;
  border-bottom: blue 5px solid;
  background-color: white;
  border-radius: 50%;
  min-height: 150px;
  min-width: 150px;
  max-height: 150px;
  max-width: 150px;
  background-size: 100% 100%;
  
}

.width100{
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}
.width20per{
  max-width: 20%;
}
.width80per{
  max-width: 80%;
}
.padding-top-5per{
  padding-top: 10%
}
.padding-bottom-5per{
  padding-bottom: 5%
}

.top-border-1px-black{
 border-top: black 1px solid;
}
.bottom-border-1px-black{
  border-bottom: black 1px solid;
 }
.p4pborderLow{
 
  margin-bottom: 10%;
  border-bottom: 1px solid black;
}
.margin-top-2per{
  margin-top: 2%;
}
.margin-0-10{
  margin: 0 10%;
}

.widh-75per{
  width:75%
}
/*

!Variables

*/

:root{
  --background-color: rgb(5, 5, 5);
  --background-color1: rgb(6, 42, 139);
  --text-color1: rgb(255, 217, 0); 
  --text-color2: rgb(255, 255, 255); 
}

/* body{
  margin:0;
  box-sizing: border-box;
  background-color: var(--background-color);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
} */

.container {
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 350px;
}

.text {
  text-transform: uppercase;
  color: var(--text-color2);
}

/* h1 {
  margin-bottom: 1em;
} */


.scoreboard {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  height: 100%;
  /* border-radius: 8px; */
  /* background-color: var(--background-color1); */
  /* border: 5px solid white; */
  /* outline: 8px solid var(--background-color1); */
}
.scoreboard2 {
 
  /* border-radius: 8px; */
  background-color: rgb(211, 211, 211); 
  border-top: 5px solid black;
  border-right: 5px solid blue;
  border-left: 5px solid red;
  /* border-bottom: 5px solid blue; */
  /* outline: 8px solid white; */

}

.team-container {
  display: flex;
  flex-direction: column;
  align-items: center;

}

/* h2 {
  font-size: 5em;
  margin-top: 0;
  margin-bottom: 10px;
} */

.one {
  position: relative;
  width: 8em;
  height: 5em;

  margin-bottom: 30px;
  background-color: #0c0c0c ;
}

.pts-shadow {
  font-family: 'Digital Display',monospace;
  font-size: 6em;
  text-align: center;

  position: absolute;
  z-index: 1;
  top: 50%;
  left: 52.5%;
  transform: translate(-50%, -50%);

  margin: 0;
  color: black;
}

.pts {
  font-family: 'Digital Display',monospace;
  font-size: 7em;
  text-align: end;

  position: relative;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  margin: 0;
  /* margin-right: 37.2px; */
  color: var(--text-color1);
}


.two {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;

  width: 10em;
}





#guest-pts {
  color: rgb(77, 77, 254)
}
#home-pts {
  color: red
}



a {
  color: var(--text-color2);
}
.pts2 {
  font-family: monospace;
  font-size: 1em;
  text-align: center;

  position: relative;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  margin: 0;
  /* margin-right: 37.2px; */
  color: var(--text-color1);
}
.pts-shadow2 {
  font-family: monospace;
  font-size: 1em;
  text-align: center;

  position: absolute;
  z-index: 1;
  top: 50%;
  left: 52.5%;
  transform: translate(-50%, -50%);

  margin: 0;
  color: black;
}
.one2 {
  position: relative;
  width: 8em;
  height: 1em;

  margin-bottom: 15px;
  background-color: #0c0c0c ;
}

.MainEventContainer{
  /* margin-top: 2%; */
  background: linear-gradient(to right, darkred 50%, navy 50%);
}
.p4pbg {
  background: linear-gradient(to right, darkred 50%, navy 50%);

}
.bold
.leagueUcontainer{
  margin: 1% 2%;
  background-color: whitesmoke;

}
.fs35{
  font-size: 35px;
}
.leag {
  background-image: url('https://www.tilingtextures.com/wp-content/uploads/2017/04/0054-2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: black 2px solid;
  border-right: navy 5px solid;
  border-left: darkred 5px solid;

}
.leagueDImg {
  background-size: 100% 100%;
  background-position: center center;
  margin: 0 5%;
  min-height: 150px;
  background-color: black;
  /* border-right: blue 5px solid;
  border-left: red 5px solid;
  border-top: red 5px solid;
  border-bottom: blue 5px solid; */
}
.p4pborder{
  border-right: blue 5px solid;
  border-left: red 5px solid;
  border-top: red 5px solid;
  border-bottom: blue 5px solid;
  
}

.width10 {
  width: 10%;
  
}
.width40{
  width:40%;
}
.width45{
  width:45%;
}
.Limgsetup {
  background-size: cover;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  justify-content: center;
  margin-left: 3%;
}
.background-dash {
  background-image: url('./backgroundwhitedash.jpeg');
  background-size: 100% 100%;
}

.Twoigs{
  padding-bottom: 3%;
}
.marginTop20{
  margin-top: 20%;
}
.fs65{
  font-size: 65px
}
.fs452{
  font-size: 45px;
}
.pt5{
  padding-top: 5%;
  padding-right: 2%;
}
.fs-mono{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.editLeagueInput{
  padding: 5px 5%;
  border: black solid 1px ;
  border-radius: 25%;
}
.marginBottom5per{
  margin-bottom: 5%;
}
@media (max-width: 767px) {

  .homebullet{
    font-size: 12px;
  }
  .moblie-small{
    font-size: 10px;
  }
  .fs452{
    font-size: 30px;
  }
  .aboutsmaller{
    font-size: 10px;
  }

  .chosenL{
    font-size: 15px;

  }
  .notLchosenL{
    font-size: 15px;

  }
  .fi {
    min-height:600px;
    
  }
  #basement{
    padding: 20px 0px;
  }
  .p4pplus{
    max-width: 100%;
    min-width: 60px;
    max-height: 40px;
    min-height: 40px;
    padding: 0%;
    
  }
  .schead {
    font-size: 9px;
    font-weight: 600
    ;
  }
  .mobile-fight-info {
    font-size: .9rem;
    color: white;
    white-space: nowrap;
  }

  .mobile-fighter-name {
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
  }
  .mobile-fighter-name.selected {
    font-size: 1.2rem;
    font-weight: bold;
    color: lightgreen;
    
  }
  .mobile-fighter-name.notselected {
    font-size: 1.2rem;
    font-weight: bold;

    
  }
  .color-black {
    color: black
  }
  .recordwcir {
    
    margin-left: 22%;
  }
  .loginsu{
    
     margin-right:25%;
  
  }
  .Left5 {
    padding-left: 1%;

  }
  .Left6 {
    padding-left: 1%;

  }
  .lboard{
    margin-left: 5%;
    margin-right: 5%;
  }
  .loading2 {
    background-image: url('./zzZZ.gif');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    min-height: '30px';
    margin-right: 15%;
    margin-left: 10%;
    padding-top: 2%;
  }

  .homeSpace{
    padding-top: 5%;
    
  }

}


@media (max-width: 640px) {
.homeSpace{
  padding-top: 0%;
}
.fs452{
  font-size: 20px;
}
.fs35{
  font-size: 25px;
}
}

@font-face {
  font-family: 'Digital Display';
  src: url('https://praxeds.github.io/scrimba-basketball-scoreboard/Assets/Digital-Display.woff2') format('woff2'),
      url('https://praxeds.github.io/basketball-scoreboard/Assets/Digital-Display.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}